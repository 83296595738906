import ContextMenuBaseController from '../utils/context_menu_base_controller'
import DesignActions from '../../src/design/actions.js'

export default class extends ContextMenuBaseController {
  static targets = ['addPhase', 'addDossier',
    'addSubassembly', 'addPart', 'addEnergy',
    'addTransport', 'paste']

  filterContextMenuClick(e){
    return e.detail.kind === 'container'
  }

  connect(){
    super.connect()

    this.addAction('keydown@document', 'handleShortcuts')

    const actions = new DesignActions(this, [], {toolbar: false, menu: 'current_node'})
    actions.updateActions({
      'pasteItem':            this.hasPasteTarget ? this.pasteTarget : null,
      'addPhase':             this.hasAddPhaseTarget ? this.addPhaseTarget : null,
      'addDossier':           this.hasAddDossierTarget ? this.addDossierTarget : null,
      'addSubassembly':       this.hasAddSubassemblyTarget ? this.addSubassemblyTarget : null,
      'addPart':              this.hasAddPartTarget ? this.addPartTarget : null,
      'addTransportStep':     this.hasAddTransportTarget ? this.addTransportTarget : null,
      'addEnergyConsumption': this.hasAddEnergyTarget ? this.addEnergyTarget : null
    })

    this.bindActions(actions)
  }

  updateActions(){
  }

  handleShortcuts(e){
    if (this.isInputKeydown(e)) return
    this.updateActions()

    if (e.key == 'h' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey && this.hasAddPhaseTarget) {
      return this._activateMenuItem(e, this.addPhaseTarget)
    }

    if (e.key == 'n' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey && this.hasAddDossierTarget) {
      return this._activateMenuItem(e, this.addDossierTarget)
    }

    if (e.key == 's' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey && this.hasAddSubassemblyTarget) {
      return this._activateMenuItem(e, this.addSubassemblyTarget)
    }

    if (e.key == 'p' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey && this.hasAddPartTarget) {
      return this._activateMenuItem(e, this.addPartTarget)
    }

    if (e.key == 't' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey && this.hasAddTransportTarget) {
      return this._activateMenuItem(e, this.addTransportTarget)
    }

    if (e.key == 'c' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey && this.hasAddEnergyTarget) {
      return this._activateMenuItem(e, this.addEnergyTarget)
    }

    if (e.key == 'V' && !e.ctrlKey && e.shiftKey && !e.metaKey && !e.altKey && this.hasPasteTarget) {
      return this._activateMenuItem(e, this.pasteTarget)
    }

    return false
  }

  getRootItem(){
    let interfaceController = this.getController('interface')

    return JSON.parse(interfaceController.data.get('root-item'))
  }
}