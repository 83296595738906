import ContextMenuBaseController from '../utils/context_menu_base_controller'
import DesignActions from '../../src/design/actions.js'

const debug = true

export default class extends ContextMenuBaseController {
  static targets = ['editLine', 'editItem', 'deleteItem', 'copyItem', 'cutItem',
    'pasteItem', 'reinitializeItem', 'infoItem', 'replaceItem', 'compareItem',
    'findInLibrary', 'fixBroken', 'updateModules', 'copyRefId', 'recomputeBroken']

  connect() {
    super.connect()
    this.names = ['node']

    const actions = new DesignActions(this, [], {menu: 'node'})
    actions.updateActions({
      'deleteItem':      this.hasDeleteItemTarget ? this.deleteItemTarget : null,
      'copyItem':        this.copyItemTarget,
      'cutItem':         this.hasCutItemTarget ? this.cutItemTarget : null,
      'pasteItem':       this.hasPasteItemTarget ? this.pasteItemTarget : null,
      'editLine':        this.hasEditLineTarget ? this.editLineTarget : null,
      'infoItem':        this.infoItemTarget,
      'replaceItem':     this.hasReplaceItemTarget ? this.replaceItemTarget : null,
      'fixBroken':       this.hasFixBrokenTarget ? this.fixBrokenTarget : null,
      'recomputeBroken': this.hasRecomputeBrokenTarget ? this.recomputeBrokenTarget : null,
      'updateModules':   this.hasUpdateModulesTarget ? this.updateModulesTarget : null,
      'copyRefId':       this.copyRefIdTarget,
    })

    this.bindActions(actions)
  }

  filterContextMenuClick(e) {
    return e.detail.name == 'node'
  }

  addItemToComparison(e){
    let selectedItem = this.getSelectedItems()[0].getAttribute('id')
    let moduleId = selectedItem.replace('Db::Module_', '').replace('Db::Flow_', '').replace('node_', '')
    let comparisonURL = e.target.closest('a').getAttribute('data-url')
    let newURL = comparisonURL.replace(':id', moduleId)

    window.location.href = newURL
  }

  findInLibrary(e){
    const selectedItem = this.getSelectedItems()[0]
    const itemExtId = selectedItem.dataset.dbItemExtId
    const itemId = selectedItem.dataset.dbItemId
    const categoryId = selectedItem.dataset.categoryId
    const typeName = selectedItem.dataset.type
    const library = document.querySelector('turbo-frame#design-library')
    if (debug) console.log('selectedItem = %o, categoryId = %o, library = %o', selectedItem, categoryId, library)
    let src = library.src
    if (!src.includes('?')) src = `${src}?`
    src = `${src}&opened_category_ids[]=${categoryId}`
    src = `${src}&selected_item_ext_id=${itemExtId}`
    src = `${src}&selected_item_id=${itemId}`
    src = `${src}&tab=${typeName}s`
    library.src = src
  }

  showNodeContextMenu(e){
    if (!this._handleSelection(e)) return false
    let menu = document.getElementById('node-context-menu')
    this._showMenu(menu)
    this.updateActions()
    this._placeMenu(e.clientX, e.clientY, menu)
  }

  updateActions(){
    let menuItems = [
      this.hasEditLineTarget ? this.editLineTarget : null,
      this.hasEditItemTarget ? this.editItemTarget : null,
      this.hasDeleteItemTarget ? this.deleteItemTarget : null,
      this.copyItemTarget,
      this.hasCutItemTarget ? this.cutItemTarget : null,
      this.infoItemTarget,
      this.hasReplaceItemTarget ? this.replaceItemTarget : null,
      this.hasCompareItemTarget ? this.compareItemTarget : null,
      this.findInLibraryTarget
    ].filter(x => x)

    // Actions restricted for admins
    let adminMenuItems = [this.copyRefIdTarget]
    if (this.hasReinitializeItemTarget) adminMenuItems.unshift(this.reinitializeItemTarget)
    if (this.hasRecomputeBrokenTarget) adminMenuItems.unshift(this.recomputeBrokenTarget)


    let allowMultiSelectionMenuItems = [this.copyItemTarget]
    if (this.hasCutItemTarget) allowMultiSelectionMenuItems.push(this.cutItemTarget)

    this._disableMenuItems(menuItems)

    let selectedItems = this.getSelectedItems()
    let currentItem = this.getController('current-view').currentItem

    this.actions.updateItems(selectedItems, currentItem)

    if (selectedItems.length > 1) {
      menuItems = allowMultiSelectionMenuItems
    }

    if (selectedItems.length > 0) {
      this._enableMenuItems(menuItems)
      let values = Array.prototype.map.call(selectedItems, e => e.value)
      let node = selectedItems[0]
      let id = node.getAttribute('value')
      let type = node.getAttribute('data-type')

      this.role_true_admin = JSON.parse(document.body.dataset.lcasRoleTrueAdmin || 'false')
      if (!this.role_true_admin) {
        this._hideMenuItems(adminMenuItems)
      } else {
        this._enableMenuItems(adminMenuItems)
        if (this.hasReinitializeItemTarget) this.reinitializeItemTarget.setAttribute('href', this._buildMultiUrl(values, 'reinitialize'))
      }

      if (selectedItems.length == 1 && this.hasEditItemTarget) this.editItemTarget.setAttribute('href', this._buildMakeUrl(type, id, 'edit') )

      if (this.hasCutItemTarget) this.cutItemTarget.setAttribute('href', this._buildMultiUrl(values, 'cut'))

      if (!['module', 'flow'].includes(type)){
        if (this.hasReplaceItemTarget) this.replaceItemTarget.classList.add('disabled')
        if (this.hasCompareItemTarget) this.compareItemTarget.classList.add('disabled')
        this.findInLibraryTarget.classList.add('disabled')
      }

      if (type === 'module'){
        const db_id = encodeURIComponent(node.getAttribute('data-db-item-id'))
        const case_study_id = encodeURIComponent(this.getRootItem().id)
        let infoUrl = `/database/${ _.pluralize(type) }/${ db_id }?case_study_ref_id=${case_study_id}`
        this.infoItemTarget.setAttribute('href', infoUrl)
        this.infoItemTarget.setAttribute('target', '_blank')
      } else {
        this.infoItemTarget.classList.add('disabled')
      }
    }

    if (this.hasPasteItem) this.pasteItemTarget.setAttribute('href', this._buildMultiUrl([], 'paste'))
  }

  handleShortcuts(e){
    if (this.isInputKeydown(e)) return
    this.updateActions()

    if (e.key == 'e' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey && this.hasEditLineTarget) {
      return this._activateMenuItem(e, this.editLineTarget)
    }

    if (e.key == 'E' && !e.ctrlKey && e.shiftKey && !e.metaKey && !e.altKey && this.hasEditItemTarget) {
      return this._activateMenuItem(e, this.editItemTarget)
    }

    if (e.key == 'C' && !e.ctrlKey && e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.copyItemTarget)
    }

    if (e.key == 'X' && !e.ctrlKey && e.shiftKey && !e.metaKey && !e.altKey && this.hasCutItemTarget) {
      return this._activateMenuItem(e, this.cutItemTarget)
    }

    if (e.key == 'Delete' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey && this.hasDeleteItemTarget) {
      return this._activateMenuItem(e, this.deleteItemTarget)
    }

    return false
  }

  getRootItem(){
    let interfaceController = this.getController('interface')

    return JSON.parse(interfaceController.data.get('root-item'))
  }
}
