import BaseActions from '../base_actions.js'
import ProjectImporter from './importer.js'
import Modal from '../lib/modal.js'

const debug = window.lcas_debug?.includes('project-actions') // ?debug[project-actions]=1

export default class ProjectActions extends BaseActions {

  constructor(controller, items, context, current_item, team_manager){
    super(controller, items, context, current_item)
    this.team_manager = team_manager
  }

  editLine(context) {
    let label = this.item.labels[0]
    let item_id = this.item.value
    let old_name = label.dataset.name
    this.helperEditLine(label, {
      title: I18n.t('project.actions.edit_line.name'),
      template: document.querySelector('template#edit-line-form'),
      action: Routes.make_cs_item_path({ref_id: item_id, interface: 'project'}),
      onOpen: (form) => {
        form.querySelector("input[name='cs_item[name]']").setAttribute('value', old_name)
      }
    })
  }

  editItem(context){
    this.openForm(this._buildMakeUrl(this.item.dataset.type, this.item.value, 'edit'))
  }

  copyItem(context){
    this.submitHiddenForm(this._buildMultiUrl(this.itemsRefId, 'copy'))
  }

  cutItem(context){
    this.submitHiddenForm(this._buildMultiUrl(this.itemsRefId, 'cut'))
  }

  pasteItem(context){
    let item = this.currentItem
    if (!context.toolbar) item = this.item || item
    this.submitHiddenForm(Routes.paste_make_cs_items_path({parent_ref_id: item.value}))
  }

  async deleteItem(context){
    const values = Array.from(this.items, e => e.value)
    await this._deleteItemImplem(context, values, async json => {
      if (debug || this.debugOnce) console.log(json)
      if (json.num_items > json.num_items_by_type['Cs::Project']) {
        const m = new Modal({text: I18n.t('project.delete_denied')})
        await m.enable()
        await m.interacted()
        return false
      }
    })
  }

  duplicate(context){
    this.submitHiddenForm(this._buildMultiUrl(this.itemsRefId, 'duplicate'))
  }

  import(context){
    if (context.toolbar || context.menu == 'current_node') {
      new ProjectImporter(this.currentItem.dataset.id, this.reload).import()
    } else {
      new ProjectImporter(this.selection.node.value, this.reload).import()
    }
  }

  importStatus(ctx) {
    const target = (ctx.toolbar || ctx.menu == 'current_node') ?
      this.currentItem :
      this.selection.node
    return {
      enabled: target && this.can_contain(target) && this.is_writable(target),
      visible: target && this.can_contain(target)
    }
  }

  archive(context){
    this.submitHiddenForm(Routes.toggle_archive_make_cs_project_path(this.item.value))
  }

  transformToTemplate(context){
    this.submitHiddenForm(this._buildMakeUrl(this.itemType, this.item.value, 'mark-template'))
  }

  defineGlobalTemplate(context){
    //this.openForm(Routes.make_cs_case_study_templates_collection_index_path(this.item.value))
    this.submitHiddenForm(Routes.make_cs_case_study_templates_collection_index_path(this.item.value, {instance_id: 'toggle-current'}))
  }

  unarchive(context){
    return this.archive(context)
  }

  share(context){
    this.submitHiddenForm(this._buildMakeUrl('cs_item', this.item.value, 'share'))
  }

  bookmark(context){
    this.submitHiddenForm(this._buildMakeUrl('cs_item', this.item.value, 'bookmark'))
  }

  status(context){
    this.openForm(this._buildMakeUrl('cs_item', this.item.value, 'statuses'))
  }

  addProject(context){
    this.openForm(Routes.new_make_cs_project_path({interface: 'project'}))
  }

  addFolder(context){
    this.openForm(Routes.new_make_cs_folder_path({parent_ref_id: (this.currentItem.dataset.id || this.currentItem.value), interface: 'project'}))
  }

  addCaseStudy(context){
    this.openForm(Routes.new_make_cs_case_study_path({parent_ref_id: (this.currentItem.dataset.id || this.currentItem.value), interface: 'project'}))
  }

  fixBroken(context) {
    let values = Array.from(this.items, e => e.value)
    this.submitHiddenForm(Routes.multi_fix_broken_make_cs_items_path({
      interface: document.body.dataset.interface,
      parent_ref_id: this.currentItem.value
    }), 'POST', {
      'ref_ids[]': values,
    })
  }

  updateModules(context) {
    let values = Array.from(this.items, e => e.value)
    this.submitHiddenForm(Routes.multi_update_version_make_cs_items_path({
      interface: document.body.dataset.interface,
      parent_ref_id: this.currentItem.value
    }), 'POST', {
      'ref_ids[]': values,
    })
  }

  removeAnalysisResults(context) {
    this.submitHiddenForm(Routes.analysis_root_cleanup_path(this.item.value))
  }

  goToComparison(context) {
    this.goTo(Routes.new_comparison_comparison_path( { 'comparison': { 'referent_item_ref_id': this.item.value } }))
  }

  // Protected

  can_contain(control) {
    return ['folder', 'project', 'Cs::Project', 'Cs::Folder'].includes(control?.dataset?.type)
  }

  updateAction(actname, action) {
    this.debugOnce = debug || window.lcas_debug?.includes(`project-action-${actname}`)
    if (this.debugOnce) {
      console.log('ProjectActions#updateAction(%o, %o) items.length=%o', actname, action, this.items.length)
    }

    let fixedModules = this.item ? this.item.dataset.fixedModules == 'true' : false
    this.disable(action)

    switch (actname) {
    case 'archive':
    case 'unarchive':
      this.show(action, this.team_manager, true, this.itemsType == 'project')
      break

    case 'firstDivider':
    case 'clipboardDivider':
    case 'typeSpecificDivider':
      this.show(action, true)
      break

    case 'caseStudyDivider':
      this.show(action, false)
      break

    case 'fixBroken': {
      const num_broken = this.items.map(i => parseInt(i.dataset.numBroken, 10)).reduce((res, n) => (res + n), 0)
      this.show(action, fixedModules, fixedModules && this.itemType == 'case-study', num_broken > 0)
      return
    }

    case 'updateModules':
      this.show(action, !fixedModules, !fixedModules && this.itemType == 'case-study', this.owner)
      return

    case 'removeAnalysisResults':
      this.show(action, true, this.items.length == 1 && this.itemType == 'case-study', true)
      return

    case 'copyRefId': return this.show(action, this.role_true_admin && this.items.length >= 1, true, true)

    //case 'addProject':
    //  this.show(action, true, this.team_manager && this.itemType == 'root', true)
    //  return

    }

    if (this.items.length == 0) {
      switch (actname) {
      case 'typeSpecificDivider': return this.show(action, false)
      case 'pasteItem': return this.enable(action, true)
      }
    } else if (this.items.length > 1) {
      let excludedClip = ['root', 'project']
      let clipAllowed = this.items.map(i => i.dataset.type).reduce((res, t) => (res && ! excludedClip.includes(t)), true)

      switch (actname) {
      case 'cutItem': return this.show(action, true, true, clipAllowed && this.owner)
      case 'copyItem': return this.show(action, true, true, clipAllowed)

      case 'deleteItem': return this.show(action, true, true, this.owner)

      default: return this.disable(action)
      }

    } else if (this.items.length == 1) {
      let item = this.items[0]
      let type = item.dataset.type
      let can_copy = type == 'folder' || type == 'case-study'
      let can_action = type != 'project' || this.team_manager
      let can_contain = type == 'folder' || type == 'project'
      let archived = (item.dataset.archived == 'true')

      switch (actname){
      case 'addProject': return this.show(action, this.team_manager, type == 'root', true)
      case 'addFolder': return this.show(action, true, can_contain, this.currentWritable)
      case 'addCaseStudy': return this.show(action, true, can_contain, this.currentWritable)

      case 'containerDividers':
        this.show(action, type != 'root')
        break

      case 'editLine': return this.show(action, true, can_action, this.owner)
      case 'editItem': return this.show(action, true, can_action, this.owner)
      case 'deleteItem': return this.show(action, true, can_action, this.owner)
      case 'cutItem': return this.show(action, true, can_copy, this.owner)
      case 'transformToModule': return this.show(action, true, type == 'case-study', this.owner && false) // NOT IMPLEMENTED
      case 'transformToTemplate': return this.show(action, this.role_team_manager, type == 'case-study', this.owner)
      case 'defineGlobalTemplate': return this.show(action, this.role_admin, type == 'case-study', this.owner)
      case 'bookmark': return this.show(action, true, type == 'case-study', this.owner)
      case 'share': return this.show(action, this.role_team_manager, type == 'case-study', this.owner)
      case 'status': return this.show(action, true, type == 'case-study', this.owner)
      case 'reinitializeItem': return this.show(action, this.role_true_admin, true, true)
      case 'goToComparison': return this.show(action, true, type == 'case-study', this.owner)
      case 'export': return this.show(action, true, type == 'case-study', this.owner)

      case 'import': return this.show(action, true, can_contain, this.currentWritable, this.itemWritable)
      case 'pasteItem': return this.show(action, true, can_contain, this.currentWritable && true)

      case 'copyItem': return this.show(action, true, can_copy, true)
      case 'duplicate': return this.show(action, true, can_copy, true)

      case 'archive': return this.show(action, this.team_manager && !archived, type == 'project', this.owner)
      case 'unarchive': return this.show(action, this.team_manager && archived, type == 'project', this.owner)

      case 'firstDivider':
      case 'clipboardDivider':
      case 'typeSpecificDivider':
        if (type == 'root') this.show(action, false)
        break

      case 'caseStudyDivider':
        if (type == 'case-study') this.show(action, true)
        break

      default: console.error(`Unknown action ${actname}`)
      }
    }
  }

}
